import { useState } from "react";
import { Box, Flex, Image, Text, Link as L, Heading } from "@chakra-ui/react";
import { Container } from "components/shared";
import { Link } from "react-router-dom";
import { BsTwitter, BsInstagram, BsWhatsapp } from "react-icons/bs";
import logo from "assets/images/logo.png";
import googlePlay from "assets/images/andro.png";
import appleStore from "assets/images/apple.png";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  const FooterLink = ({ to, name, ...props }) => {
    const [hoverLink, setHoverLink] = useState(false);

    return (
      <Box {...props}>
        <Box
          transition="0.3s"
          as={Link}
          to={to}
          color="#677294"
          lineHeight="26px"
          textDecor="none"
          textTransform="capitalize"
          _hover={{ color: "text.mainColor" }}
          onMouseOver={() => setHoverLink(true)}
          onMouseLeave={() => setHoverLink(false)}
          pos="relative">
          {name}
          <Box
            w={hoverLink ? "100%" : "0"}
            h="1px"
            bg="bg.mainColor"
            transition="0.3s"
            pos="absolute"
            bottom="-5px"
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box bg="#eff2f9" pt="100px">
      <Container>
        <Flex
          mb="50px"
          justifyContent="space-between"
          flexWrap={["wrap", "", "nowrap", ""]}
          px={["5%", "0", "", ""]}>
          <Box w={["100%", "50%", "30%", ""]}>
            <Image src={logo} w="180px" mb="40px" />
            <Text color="#677294" lineHeight="26px" mb="20px">
              {t("footer.aboutText")}
            </Text>

            <Text mb="10px">
              {t("footer.email")}:
              <Text as="span" color="#677294">
                {" "}
                help@aerbag.app
              </Text>
            </Text>

            <Text>
              {t("footer.phone")}:
              <Text as="span" color="#677294">
                {" "}
                920034054
              </Text>
            </Text>
            {/* <Image src={zatiqa} mt="20px" /> */}
          </Box>

          <Box w={["50%", "36%", "initial", ""]} mt={["40px", "0", "", ""]}>
            <Heading
              as="h3"
              textTransform="uppercase"
              fontWeight="bold"
              fontSize="18px"
              mb="50px">
              {t("footer.help")}
            </Heading>
            <FooterLink
              to="/terms"
              name={t("footer.terms_conditions")}
              mb="20px"
            />
            <FooterLink
              to="/privacy"
              name={t("footer.privacy_policy")}
              mb="20px"
            />
            {/* <FooterLink to="/career" name={t("footer.career")} mb="20px" /> */}
            {/* <FooterLink to="/help" name={t("footer.help")} /> */}
          </Box>

          <Box mt={["40px", "", "0", ""]} w={["40%", "", "initial", ""]}>
            <Heading
              as="h3"
              textTransform="uppercase"
              fontWeight="bold"
              fontSize="18px"
              mb="50px">
              {t("footer.company")}
            </Heading>
            {/* <FooterLink to="#" name={t("footer.about_us")} mb="20px" /> */}
            <FooterLink to="/about-us" name={t("header.about")} mb="20px" />
            <FooterLink
              to="/solutions"
              name={t("footer.service_providers")}
              mb="20px"
            />
            {/* <FooterLink to="#" name={t("footer.reporting")} mb="20px" />
            <FooterLink to="#" name={t("footer.work_tracking")} /> */}
          </Box>

          <Box
            w={["100%", "36%", "initial", ""]}
            mt={["40px", "", "0", "0"]}
            display={["flex", "block", "", ""]}
            justifyContent="center">
            <Heading
              as="h3"
              textTransform="uppercase"
              fontWeight="bold"
              fontSize="18px"
              mb="50px"
              display={["none", "block", "", ""]}>
              {t("footer.download")}
            </Heading>
            <L
              href="https://play.google.com/store/apps/details?id=com.intcore.aerbag"
              target="_blank"
              display="block"
              mb="15px"
              me={["5px", "0", "", ""]}
              _hover={{}}>
              <Image src={googlePlay} borderRadius="5px" />
            </L>

            <L
              href="https://apps.apple.com/us/app/aerbag/id1532251378"
              target="_blank">
              <Image src={appleStore} borderRadius="5px" />
            </L>
          </Box>
        </Flex>
      </Container>
      <Box borderTop="1px solid #e0e3ef" py="20px">
        <Container>
          <Flex
            justifyContent="space-between"
            color="#677294"
            flexWrap={["wrap", "nowrap", "", ""]}>
            <Text
              w={["100%", "initial", "", ""]}
              textAlign={["center", "initial", "", ""]}
              mb={["15px", "0", "", ""]}>
              {t("footer.copyright")}
            </Text>

            <Flex
              alignItems="center"
              w={["100%", "initial", "", ""]}
              justifyContent={["center", "initial", "", ""]}
              mb={["15px", "0", "", ""]}>
              <L href="https://twitter.com/aerbagapp" target="_blank">
                <Box as={BsTwitter} />
              </L>
              <L
                mx="15px"
                href="https://instagram.com/aerbagapp"
                target="_blank">
                <Box as={BsInstagram} />
              </L>
              <L href="https://wa.me/966920034054" target="_blank">
                <Box as={BsWhatsapp} />
              </L>
            </Flex>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
