import { Box } from "@chakra-ui/react";
import {
  Intro,
  WhoWeAre,
  Feauters,
  Services,
  DownloadApp,
} from "components/towing";

const Towing = () => {
  return (
    <Box>
      <Intro />
      <Feauters />
      <Services />
      <WhoWeAre />
      <DownloadApp />
    </Box>
  );
};

export default Towing;
