import { Box, Flex, Text, Button, Image, Grid } from "@chakra-ui/react";
import shape2 from "assets/images/crm/shape-2.png";
import img1 from "assets/images/crm/img2.png";
import img2 from "assets/images/crm/img2s.png";
import { motion } from "framer-motion";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";

const Row2 = () => {
  const { t } = useTranslation();

  const dir = localStorage.getItem("i18nextLng");

  const List = ({ text, ...props }) => {
    return (
      <Flex alignItems="center" mb="10px">
        <Grid
          me="8px"
          placeItems="center"
          bg="rgba(7, 213, 172, 0.2)"
          color="#07D5AC"
          w="20px"
          h="20px"
          borderRadius="50%"
          fontSize="12px"
          {...props}>
          1
        </Grid>
        <Text m="0" color="#6C727D">
          {text}
        </Text>
      </Flex>
    );
  };

  return (
    <Box mb="100px">
      <Flex
        flexWrap={["wrap", "", "nowrap", "nowrap"]}
        justifyContent={["initial", "", "space-between", "initial"]}>
        <Box pos="relative" w={["100%", "", "50%", "60%"]}>
          <Image src={shape2} mx={["auto", "", "0", ""]} />
          <motion.div
            animate={{
              y: [-250, -230],
            }}
            transition={{
              duration: 1.5,
              ease: "easeInOut",
              repeatType: "reverse",
              repeat: Infinity,
              type: "tween",
            }}>
            <Image
              src={img1}
              pos="absolute"
              top="50%"
              transform="translateY(-50%)"
              right={dir == "ar" ? "0" : null}
              left={dir == "en" ? "0" : null}
              filter="drop-shadow(0px 10px 50px rgba(0, 0, 0, 0.1))"
              borderRadius="5px"
              w="85%"
            />
          </motion.div>

          <motion.div
            initial={{ y: -250 }}
            animate={{
              x: [15, -15],
            }}
            transition={{
              duration: 1.5,
              ease: "easeInOut",
              repeatType: "reverse",
              repeat: Infinity,
              type: "tween",
            }}>
            <Image
              src={img2}
              pos="absolute"
              top="50%"
              w="150px"
              right={dir == "en" ? "60px" : null}
              left={dir == "ar" ? "60px" : null}
              filter="drop-shadow(0px 10px 50px rgba(0, 0, 0, 0.1))"
            />
          </motion.div>
        </Box>

        <Box
          w={["100%", "", "40%", "40%"]}
          me={["0", "50px", "50px", "50px"]}
          mt="50px">
          <ScrollAnimation animateIn="slideInUp " animateOnce={true}>
            <Box
              maxW="170px"
              bg="rgba(158, 89, 255, 0.1)"
              color="#9E59FF"
              borderRadius="35px"
              py="8px"
              textAlign="center"
              fontSize="14px"
              fontWeight="500"
              textTransform="capitalize"
              mb="20px">
              {t("crm.about.service2.text1")}
            </Box>
            <Text
              fontSize={["25px", "36px", "36px", "36px"]}
              fontWeight="bold"
              lineHeight={["30px", "50px", "50px", "50px"]}
              mb="18px"
              color="#0A1426">
              {t("crm.about.service2.text2")}
            </Text>
            <Text color="#6C727D" lineHeight="30px" mb="35px">
              {t("crm.about.service2.text3")}
            </Text>

            <Box mb="50px">
              <List text={t("crm.about.service2.text4")} />
              <List text={t("crm.about.service2.text5")} />
            </Box>

            {/* <Button
              textTransform="uppercase"
              display="flex"
              borderRadius="35px"
              p="25px"
              paddingInline="40px"
              color="#0061FE"
              border="1px solid #0061FE"
              bg="transparent"
              _hover={{ bg: "#0061FE", color: "white" }}
              _active={{}}
              _focus={{}}>
              {t("crm.about.service2.button")}
            </Button> */}
          </ScrollAnimation>
        </Box>
      </Flex>
    </Box>
  );
};

export default Row2;
