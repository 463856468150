import {
  Box,
  Heading,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/react";
import { Container } from "components/shared";
import { TfiUser, TfiEmail, TfiInfoAlt } from "react-icons/tfi";
import { useTranslation } from "react-i18next";

const Faq = () => {
  const { t } = useTranslation();

  const questions = [
    {
      title: t("main.faq.q1.heading"),
      text: t("main.faq.q1.text"),
      icon: TfiUser,
    },
    {
      title: t("main.faq.q2.heading"),
      text: t("main.faq.q2.text"),
      icon: TfiEmail,
    },
    {
      title: t("main.faq.q3.heading"),
      text: t("main.faq.q3.text"),
      icon: TfiInfoAlt,
    },
  ];

  const FaqItem = ({ title, des, icon, ...props }) => {
    return (
      <AccordionItem
        border="none"
        bg="white"
        mb="20px"
        boxShadow="0px 10px 30px 0px rgb(0 0 29 / 8%)">
        <Heading as="h2">
          <AccordionButton
            border="none"
            py="22px"
            fontSize={["16px", "18px", "20px", ""]}
            color="#8b8b99"
            _expanded={{ bg: "bg.mainColor", color: "white !important" }}
            {...props}>
            <Box
              as={icon}
              fontSize={["25px", "30px", "", ""]}
              me="20px"
            // color="text.mainColor"
            />
            <Box
              flex="1"
              textAlign="initial"
            //color="#8b8b99"
            >
              {title}
            </Box>
          </AccordionButton>
        </Heading>
        <AccordionPanel
          pb={4}
          bg="bg.mainColor"
          color="white"
          lineHeight="26px"
          fontSize={["14px", "16px", "", ""]}>
          {des}
        </AccordionPanel>
      </AccordionItem>
    );
  };

  return (
    <Box my="80px" id="faq">
      <Container>
        <Heading
          textAlign="center"
          fontWeight="semibold"
          textTransform="uppercase"
          fontSize={["25px", "28px", "30px", ""]}>
          {t("main.faq.heading")}
        </Heading>
        <Box w="40px" h="3px" bg="bg.mainColor" m="auto" mt="5px" />

        <Flex
          alignItems="center"
          justifyContent="space-between"
          mt="70px"
          flexWrap={["wrap", "", "nowrap", ""]}
          flexDir={["column-reverse", "", "row", ""]}
          px={["0", "80px", "0", ""]}>
          <Box w={["100%", "", "55%", ""]}>
            <Accordion defaultIndex={[0]}>
              {questions.map((e, i) => {
                return (
                  <FaqItem key={i} icon={e.icon} title={e.title} des={e.text} />
                );
              })}
            </Accordion>
          </Box>

          <Box w={["70%", "70%", "40%", ""]} pos="relative">
            {/* <lottie-player
              src="https://assets8.lottiefiles.com/packages/lf20_wr9avvmg.json"
              background="transparent"
              speed="1"
              style={{ width: "100%", height: "100%" }}
              loop
              autoplay
            ></lottie-player> */}

            <lottie-player
              src="https://lottie.host/07ae2e31-48b3-4923-b461-e8335d743f08/L7idicDpsW.json"
              background="transparent"
              speed="1.3"
              style={{
                width: "100%",
                height: "100%",
                zIndex: "11",
                position: "relative",
              }}
              loop
              autoplay></lottie-player>

            <Box
              w="400px"
              h="400px"
              borderRadius="50%"
              bg="rgb(239 242 249)"
              opacity="0.5"
              pos="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%,-50%)"
              zIndex="1"
              display={["none", "block", "", ""]}
            />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default Faq;
