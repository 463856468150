import { Box } from "@chakra-ui/react";
import {
  Intro,
  WhatWeDo,
  Counter,
  Offers,
  Prices,
} from "components/crm";

const Crm = () => {
  return (
    <Box>
      <Intro />
      <WhatWeDo />
      <Counter />
      <Offers />
      <Prices />
      {/* <Blogs /> */}
    </Box>
  );
};

export default Crm;
