import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { Container } from "components/shared";
import AboutSection from "./about-section";
import phone from "assets/images/servs2.png";
import { useTranslation } from "react-i18next";

const WhoWeAre = () => {
  const { t } = useTranslation();

  const data = [
    { title: t("towing.whoAreWe.text1"), text: t("towing.whoAreWe.text2") },
    { title: t("towing.whoAreWe.text3"), text: t("towing.whoAreWe.text4") },
    { title: t("towing.whoAreWe.text5"), text: t("towing.whoAreWe.text6") },
  ];

  return (
    <Box mt={["80px", "150px", "150px", "150px"]}>
      <Container>
        <Flex
          justifyContent="space-between"
          flexWrap={["wrap", "", "nowrap", ""]}
          px={["5%", "5%", "0", ""]}>
          <Box w={["100%", "", "45%", "45%"]} mb={["20px", "", "0", ""]}>
            <Text
              fontSize={["30px", "50px", "50px", "50px"]}
              fontWeight="bold"
              mb="30px">
              {t("towing.whoAreWe.title")}
            </Text>
            {data.map((e, i) => {
              return (
                <AboutSection
                  title={e.title}
                  text={e.text}
                  mb={i == data.length - 1 ? "0" : "40px"}
                />
              );
            })}
          </Box>
          <Image
            src={phone}
            w={["100%", "500px", "40%", "45%"]}
            h={["initial", "", "700px", "initial"]}
            mx={["auto", "", "0", ""]}
          />
        </Flex>
      </Container>
    </Box>
  );
};

export default WhoWeAre;
