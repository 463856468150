import { Box, Text, Grid } from "@chakra-ui/react";
import { Container } from "components/shared";
import TitleText from "../title-text";
import Card from "./card";
import Row from "./row1";
import Row2 from "./row2";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";

const WhatWeDo = () => {
  const { t } = useTranslation();

  const data = [
    {
      title: t("crm.about.card1.title"),
      text: t("crm.about.card1.text"),
    },
    {
      title: t("crm.about.card2.title"),
      text: t("crm.about.card2.text"),
    },
    {
      title: t("crm.about.card3.title"),
      text: t("crm.about.card3.text"),
    },
  ];

  return (
    <Box>
      <ScrollAnimation animateIn="slideInUp " animateOnce={true}>
        <TitleText
          title={t("crm.about.text1")}
          des={t("crm.about.text2")}
          text2={t("crm.about.text3")}
        />
      </ScrollAnimation>

      <Container>
        <ScrollAnimation animateIn="slideInUp " animateOnce={true}>
          <Grid
            gridTemplateColumns={[
              "repeat(1,1fr)",
              "repeat(2,1fr)",
              "repeat(3,1fr)",
              "",
            ]}
            px={["3%", "10%", "0", ""]}
            gap="30px"
            mb={["40px", "80px", "80px", "80px"]}>
            {data.map((e, i) => {
              return (
                <Card key={i} order={i + 1} title={e.title} text={e.text} />
              );
            })}
          </Grid>
        </ScrollAnimation>
        <Box px={["3%", "10%", "0", ""]}>
          <Row />
          <Row2 />
        </Box>
      </Container>
    </Box>
  );
};

export default WhatWeDo;
