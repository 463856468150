import { Box, Image, Text } from "@chakra-ui/react";

const ServiceCard = ({ img, text }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={["center", "center", "start", ""]}
    >
      <Image src={img} mb="15px" h="107px" />
      <Text fontSize="18px" fontWeight="bold">
        {text}
      </Text>
    </Box>
  );
};

export default ServiceCard;
