import { Box, Accordion, Text } from "@chakra-ui/react";
import { Container, HeaderPage } from "components/shared";
import { FaqItem } from "components/faq";
import { useTranslation } from "react-i18next";

const CarOwnerFaq = () => {
  const { t } = useTranslation();

  const CustomText = ({ children, ...props }) => {
    return (
      <Text fontSize="14px" color="#333" lineHeight="28px" {...props}>
        {children}
      </Text>
    );
  };

  const data = [
    {
      title: t("helpCarOwner.q1.title"),
      text: t("helpCarOwner.q1.text"),
    },
    {
      title: t("helpCarOwner.q2.title"),
      text: t("helpCarOwner.q2.text"),
    },
    {
      title: t("helpCarOwner.q3.title"),
      text: t("helpCarOwner.q3.text"),
    },

    {
      title: t("helpCarOwner.q4.title"),
      text: t("helpCarOwner.q4.text"),
    },

    {
      title: t("helpCarOwner.q5.title"),
      text: t("helpCarOwner.q5.text"),
    },
  ];

  return (
    <Box pt="150px">
      <Container>
        <HeaderPage name="FAQ: CAR OWNERS" />
        <Box px={["0", "", "80px", ""]} mb="80px">
          <Accordion defaultIndex={[0]} allowToggle>
            {data?.map((e, i) => {
              return (
                <FaqItem key={i} title={e.title} mb="20px">
                  <CustomText>{e.text}</CustomText>
                </FaqItem>
              );
            })}
          </Accordion>
        </Box>
      </Container>
    </Box>
  );
};

export default CarOwnerFaq;
