import { Box, Image, Text, ListItem, UnorderedList } from "@chakra-ui/react";

const FeatureCard = ({ title, desc, items, img }) => {
  return (
    <Box>
      <Image src={img} mb="15px" />

      <Text
        color="#333333"
        fontSize={["22px", "30px", "30px", "30px"]}
        fontWeight="bold"
        mb="20px">
        {title}
      </Text>
      <Text
        fontSize={["16px", "18px", "18px", "18px"]}
        color="#26242D"
        mb="15px">
        {desc}
      </Text>
      <UnorderedList>
        {items?.map((e, i) => {
          return (
            <ListItem color="#737678" lineHeight="25px" mb="10px">
              {e}
            </ListItem>
          );
        })}
      </UnorderedList>
    </Box>
  );
};

export default FeatureCard;
