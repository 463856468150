import { useState, useEffect } from "react";
import Layout from "layout";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "pages/home";
import Terms from "pages/terms";
import Privacy from "pages/privacy";
import Help from "pages/help";
import CarOwnerFaq from "pages/car-owner-faq";
import ServiceProviderFaq from "pages/service-provider-faq";
import Crm from "pages/crm";
import Towing from "pages/towing";
import ServiceProviders from "pages/service-providers";
import Signup from "pages/signup";
import "styles/App.css";
import { language } from "contexts/language";
import AboutUs from "pages/about-us";
import SmartUrl from "pages/smart-url";

const App = () => {
  const [lang, setLang] = useState(localStorage.getItem("i18nextLng"));

  useEffect(() => {
    document.documentElement.dir = lang == "ar" ? "rtl" : "ltr";
  }, [lang]);

  return (
    <>
      <language.Provider value={{ lang, setLang }}>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/app" element={<SmartUrl />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/help" element={<Help />} />
            <Route path="/towing" element={<Towing />} />
            <Route path="/workshop-management" element={<Crm />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/solutions" element={<ServiceProviders />} />
            <Route path="/help/car-owner" element={<CarOwnerFaq />} />
            <Route
              path="/help/service-providers"
              element={<ServiceProviderFaq />}
            />
            <Route path="*" element={<Navigate to={"/"} />} />
          </Routes>
        </Layout>
      </language.Provider>
    </>
  );
};

export default App;
