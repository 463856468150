import { Image, Flex, Box, Text } from "@chakra-ui/react";

const OfferCard = ({ title, text, img, ...props }) => {
  return (
    <Flex
      p={[
        "20px 20px 10px",
        "30px 30px 30px",
        "40px 40px 30px",
        "40px 40px 30px",
      ]}
      bg="white"
      boxShadow="0px 0px 70px rgb(0 0 0 / 5%)"
      alignItems="center"
      flexWrap={["wrap", "nowrap", "nowrap", "nowrap"]}>
      <Image src={img} w="75px" h="75px" me="20px" />
      {/* <Grid
        placeItems="center"
        w={["80px", "150px", "150px", "150px"]}
        h="70px"
        borderRadius="65% 35% 40% 60%/60% 42% 58% 40%"
        me="40px"
        mb={["30px", "0", "", ""]}
        bg="red"
        {...props}>
        1
      </Grid> */}

      <Box w={["100%", "initial", "initial", "initial"]}>
        <Text
          fontSize={["18px", "22px", "22px", "22px"]}
          lineHeight="32px"
          fontWeight="bold"
          mb="10px">
          {title}
        </Text>
        <Text
          color="#6C727D"
          lineHeight="30px"
          fontSize={["14px", "16px", "", ""]}>
          {text}
        </Text>
      </Box>
    </Flex>
  );
};

export default OfferCard;
