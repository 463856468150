import { Box, Text, Flex, Link, Heading, Image } from "@chakra-ui/react";
import { Container } from "components/shared";
import googlePlay from "assets/images/google-play.png";
import appleStore from "assets/images/apple-store.png";
import phone from "assets/images/phone.png";

const SignUp = () => {
  return (
    <Box my="50px" pt="100px">
      <Container>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          pos="relative"
          flexWrap={["wrap", "", "nowrap", "nowrap"]}
          flexDirection={["column-reverse", "", "row", ""]}
        >
          <Box w={["100%", "", "60%", ""]}>
            <Heading
              as="h2"
              fontWeight="bold"
              fontSize={["30px", "36px", "45px", ""]}
              mb="20px"
              textAlign={["center", "", "initial", ""]}
            >
              موقع لوحة صار في تطبيق!
            </Heading>

            <Text
              w={["90%", "80%", "85%", ""]}
              lineH="28px"
              textAlign={["center", "", "initial", ""]}
              m={["auto", "", "0", ""]}
            >
              لإكمال عملية التسجيل، حمل تطبيق إيرباق و أستفيد من كافة الخدمات
              اللي نقدمها من لوح سيارات، صيانة السيارات، و المساعدة على الطريق.
            </Text>

            <Flex
              mt="40px"
              justifyContent={["center", "", "initial", ""]}
              flexWrap={["wrap", "nowrap", "", ""]}
            >
              <Link
                me={["5px", "10px", "", ""]}
                mb={["10px", "0", "", ""]}
                href="https://play.google.com/store/apps/details?id=com.intcore.aerbag"
              >
                <Image src={googlePlay} borderRadius="5px" />
              </Link>

              <Link href="https://apps.apple.com/us/app/aerbag/id1532251378">
                <Image src={appleStore} borderRadius="5px" />
              </Link>
            </Flex>
          </Box>
          <Box w={["100%", "", "40%", ""]} mb={["20px", "", "0", ""]}>
            <Image src={phone} m={["auto", "", "0", ""]} />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default SignUp;
