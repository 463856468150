import { Heading, Box } from "@chakra-ui/react";

const HeaderPage = ({ name, ...props }) => {
  return (
    <Box mb="50px">
      <Heading
        textAlign="center"
        fontWeight="semibold"
        textTransform="uppercase"
        fontSize={["25px", "", "34px", ""]}
        {...props}
      >
        {name}
      </Heading>
      <Box w="55px" h="3px" bg="bg.mainColor" m="auto" mt="15px" />
    </Box>
  );
};

export default HeaderPage;
