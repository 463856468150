import MobileDetect from 'mobile-detect';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // If you're using React Router

const SmartUrl = () => {
    const location = useLocation();
    useEffect(() => {
        const md = new MobileDetect(window.navigator.userAgent);

        // Parse query parameters
        const queryParams = new URLSearchParams(location.search);
        const service = queryParams.get('service');

        const appStoreUrl = 'https://apps.apple.com/us/app/aerbag/id1532251378';
        const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.intcore.aerbag';

        if (md.os() === 'iOS') {
            window.location.href = appStoreUrl;
        } else if (md.os() === 'AndroidOS') {
            window.location.href = playStoreUrl;
        } else {
            if (service === 'lu7ah') {
                window.location.href = 'https://lu7ah.com';
            } else {
                window.location.href = "/";
            }
        }
    }, []);
    return null;
};

export default SmartUrl;
