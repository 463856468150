import { Box, Link, Text, Image } from "@chakra-ui/react";
import { Container } from "components/shared";
import { useTranslation } from "react-i18next";
import aboutImage from "assets/images/service-provider.svg";

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <Box pt="150px" pb="80px">
      <Container>
        <Image
          src={aboutImage}
          h="450px"
          w="100%"
          objectFit="cover"
          mb="50px"
        />
        <Text
          textTransform="capitalize"
          fontWeight="bold"
          fontSize="50px"
          mb="20px">
          {t("header.about")}
        </Text>

        <Text mb="15px">{t("aboutPage.text1")}</Text>

        <Text mb="15px">{t("aboutPage.text2")}</Text>

        <Text>
          {t("aboutPage.text3")}{" "}
          <Link
            href="mailto:help@aerbag.app"
            color="text.mainColor"
            textDecor="underline">
            help@aerbag.app
          </Link>
        </Text>
      </Container>
    </Box>
  );
};

export default AboutUs;
