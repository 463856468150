import { Box, Grid } from "@chakra-ui/react";
import FeatureCard from "./feature-card";
import { Container } from "components/shared";
import f1 from "assets/images/crm1/f_3.svg";
import f2 from "assets/images/crm1/f_2.svg";
import f3 from "assets/images/crm1/f_1.svg";
import { useTranslation } from "react-i18next";

const Feauters = () => {
  const { t } = useTranslation();

  const data = [
    {
      img: f1,
      title: t("towing.features.card1.title"),
      desc: t("towing.features.card1.text1"),
      items: [
        t("towing.features.card1.text2"),
        t("towing.features.card1.text3"),
      ],
    },
    {
      img: f2,
      title: t("towing.features.card2.title"),
      desc: t("towing.features.card2.text1"),
      items: [
        t("towing.features.card2.text2"),
        t("towing.features.card2.text3"),
      ],
    },
    {
      img: f3,
      title: t("towing.features.card3.title"),
      desc: t("towing.features.card3.text1"),
      items: [
        t("towing.features.card3.text2"),
        t("towing.features.card3.text3"),
      ],
    },
  ];

  return (
    <Box mt="100px">
      <Container>
        <Grid
          gridTemplateColumns={[
            "repeat(1,1fr)",
            "repeat(1,1fr)",
            "repeat(3,1fr)",
            "repeat(3,1fr)",
          ]}
          gap="30px"
          px={["5%", "5%", "0", ""]}>
          {data.map((e, i) => {
            return (
              <FeatureCard
                img={e.img}
                title={e.title}
                desc={e.desc}
                items={e.items}
              />
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
};

export default Feauters;
