import { Box, Grid } from "@chakra-ui/react";
import TitleText from "../title-text";
import OfferCard from "./offer-card";
import { Container } from "components/shared";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";
import quickBooks from "assets/images/crm/quickbooks.png";
import bookings from "assets/images/crm/bookings.png";
import reports from "assets/images/crm/reports.png";
import techApp from "assets/images/crm/tech-app.png";

const Offers = () => {
  const { t } = useTranslation();

  const data = [
    {
      title: t("crm.services.card1.title"),
      text: t("crm.services.card1.text"),
      bg: "rgba(0, 97, 254, 0.2)",
      color: "#3938B9",
      img: quickBooks,
    },
    {
      title: t("crm.services.card2.title"),
      text: t("crm.services.card2.text"),
      bg: "rgba(255, 71, 87, 0.2)",
      color: "#FF4757",
      img: bookings,
    },
    {
      title: t("crm.services.card3.title"),
      text: t("crm.services.card3.text"),
      bg: "rgba(7, 213, 172, 0.2)",
      color: "#07D5AC",
      img: reports,
    },
    {
      title: t("crm.services.card4.title"),
      text: t("crm.services.card4.text"),
      bg: "rgba(89, 86, 233, 0.2)",
      color: "#3938B9",
      img: techApp,
    },
  ];

  return (
    <Box mb="100px">
      <ScrollAnimation animateIn="slideInUp " animateOnce={true}>
        <TitleText
          title={t("crm.services.text1")}
          des={t("crm.services.text2")}
          text2={t("crm.services.text3")}
        />
      </ScrollAnimation>

      <Container>
        <ScrollAnimation animateIn="slideInUp " animateOnce={true}>
          <Grid
            gridTemplateColumns={[
              "repeat(1,1fr)",
              "repeat(1,1fr)",
              "repeat(2,1fr)",
              "",
            ]}
            gap="30px"
            px={["5%", "20%", "0", ""]}
            mb="100px">
            {data?.map((e) => {
              return (
                <OfferCard
                  title={e.title}
                  text={e.text}
                  bg={e.bg}
                  color={e.color}
                  img={e.img}
                />
              );
            })}
          </Grid>
        </ScrollAnimation>
      </Container>
    </Box>
  );
};

export default Offers;
