import { Box, Text, Button, Grid } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const DownloadApp = () => {
  const { t } = useTranslation();

  return (
    <Grid
      bg="linear-gradient(12deg, #00ad7c 30%, #97b44c)"
      py="100px"
      mt="120px"
    //placeItems="center"
    >
      <Box>
        <Text
          textAlign="center"
          color="white"
          fontSize={["30px", "52px", "52px", "52px"]}
          fontWeight="bold"
          mb="15px">
          {t("towing.partner.text1")}
        </Text>
        <Text
          textAlign="center"
          color="white"
          fontSize={["16px", "18px", "18px", "18px"]}
          mb="40px">
          {t("towing.partner.text2")}
        </Text>
        <Button
          color="#8f09f9"
          display="flex"
          m="auto"
          bg="white"
          fontWeight="bold"
          p="30px"
          fontSize={["20px", "25px", "25px", "25px"]}
          py="40px"
          _hover={{}}
          _focus={{}}
          _active={{}}>
          {t("towing.partner.button")}
        </Button>
      </Box>
    </Grid>
  );
};

export default DownloadApp;
