import { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Image,
  Button,
  Text,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Scrollspy from "react-scrollspy";
import logo from "assets/images/logo.png";
import logoWhite from "assets/images/aerbag-white.png";
import { FiMenu } from "react-icons/fi";
import { Lang, Container } from "components/shared";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [isCrmPage, setIsCrmPage] = useState(false);
  const [isHomePage, setIsHomePage] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname == "/") {
      setIsHomePage(true);
    } else {
      setIsHomePage(false);
    }

    if (pathname == "/workshop-management") {
      setIsCrmPage(true);
    } else {
      setIsCrmPage(false);
    }
  }, [pathname]);

  const NavLink = ({ to, name, lineWidth, ...props }) => {
    return (
      <Box
        as={HashLink}
        to={to}
        fontWeight="semibold"
        textDecor="none"
        _hover={{}}
        textTransform="capitalize"
        {...props}>
        {name}
        <Box w="0" h="1px" bg="black" transition="0.3s" />
      </Box>
    );
  };

  const links = [
    {
      to: "/#home",
      name: t("header.home"),
    },
    {
      to: "/about-us",
      name: t("header.about"),
    },
    {
      to: "/#services",
      name: t("header.services"),
    },
    {
      to: "/#faq",
      name: t("header.faq"),
    },
  ];

  window.onscroll = function () {
    if (window.scrollY > 100) {
      setIsScrolling(true);
    } else {
      setIsScrolling(false);
    }
  };

  return (
    <>
      <Box
        bg={isCrmPage && !isScrolling ? "transparent" : "white"}
        py="20px"
        pos="fixed"
        top="0"
        w="100%"
        transition="0.3s"
        boxShadow={
          isCrmPage && !isScrolling
            ? "none"
            : "0px 4px 6px 0px rgb(12 0 46 / 6%)"
        }
        zIndex="222">
        <Container>
          <Flex m="auto" justifyContent="space-between" alignItems="center">
            <Box as={Link} to="/">
              <Image
                src={isCrmPage && !isScrolling ? logoWhite : logo}
                w="208px"
              />
            </Box>

            {isHomePage ? (
              <Box
                as={Scrollspy}
                items={["home", "about", "services", "faq"]}
                currentClassName="is-current"
                display={["none", "", "flex", ""]}
                alignItems="center"
                offset={-5}>
                {links.map((e, i) => {
                  return <NavLink key={i} to={e.to} name={e.name} me="20px" />;
                })}
              </Box>
            ) : null}

            {/* <Button
            fontWeight="normal"
            p="25px"
            border="2px solid #29B3FB"
            bg="transparent"
            color="text.mainColor"
            display={["none", "", "flex", ""]}
            _hover={{
              bg: "bg.mainColor",
              color: "white",
              borderColor: "transparent",
            }}
          >
            Service provider ? click here
          </Button> */}

            <Flex alignItems="center">
              {isCrmPage ? (
                <Button
                  me="20px"
                  display={["none", "", "flex", ""]}
                  px="40px"
                  as={"a"}
                  href="https://portal.aerbag.app"
                  target="_blank">
                  Sign In
                </Button>
              ) : null}
              <Box
                display={["none", "", "block", ""]}
                color={isCrmPage && !isScrolling ? "white" : "black"}>
                <Lang />
              </Box>
            </Flex>

            <Box
              as={FiMenu}
              display={["block", "", "none", ""]}
              fontSize="35px"
              cursor="pointer"
              color={isCrmPage && !isScrolling ? "white" : "black"}
              onClick={() => setOpen(true)}
            />
          </Flex>
        </Container>
      </Box>

      {/* mobile drawer */}
      <Box display={["block", "", "none", ""]}>
        <Box
          pos="fixed"
          top="0"
          left="0"
          w="100%"
          h="100%"
          bg="rgba(0,0,0,0.5)"
          zIndex="555"
          opacity={open ? "1" : "0"}
          visibility={open ? "visible" : "hidden"}
          transition="0.3s"
          onClick={() => setOpen(false)}
        />

        <Box
          pos="fixed"
          top="0"
          bottom="0"
          right={open ? "0" : "-120%"}
          bg="white"
          zIndex="5555"
          transition="0.3s">
          <Box as={Link} to="/" _hover={{}} _active={{}} _focus={{}}>
            <Image
              src={logo}
              w="100%"
              p="15px"
              onClick={() => setOpen(false)}
              mb="20px"
            />
          </Box>
          {isHomePage ? (
            <Box bg="#f9f9f9">
              {links.map((e, i) => {
                return (
                  <Flex
                    key={i}
                    as={HashLink}
                    to={e.to}
                    alignItems="center"
                    p="15px"
                    borderBottom="1px solid gainsboro"
                    onClick={() => setOpen(false)}>
                    <Text color="text.blackColor">{e.name}</Text>
                  </Flex>
                );
              })}
            </Box>
          ) : null}

          {isCrmPage ? (
            <Button
              mx="20px"
              display={["flex", "", "none", ""]}
              p="25px"
              px="40px"
              as={"a"}
              href="https://portal.aerbag.app"
              target="_blank">
              Sign In
            </Button>
          ) : null}

          <Flex
            mt="20px"
            justifyContent="center"
            onClick={() => setOpen(false)}>
            <Lang
              w="100%"
              mx="20px"
              bg="#ebebeb"
              cursor="pointer"
              p="10px"
              px="40px"
              borderRadius="10px"
              isCenter
            />
          </Flex>

          {/* <Box m="15px">
            <Button
              fontWeight="normal"
              p="25px"
              border="2px solid #29B3FB"
              bg="transparent"
              color="text.mainColor"
              display={["none", "", "flex", ""]}
              _hover={{
                bg: "bg.mainColor",
                color: "white",
                borderColor: "transparent",
              }}
            >
              Service provider ? click here
            </Button>
          </Box> */}
        </Box>
      </Box>
    </>
  );
};

export default Header;
