import { Box, Text, Grid, Button } from "@chakra-ui/react";
import { Container } from "components/shared";
import ServiceCard from "./service-card";
import hoodup from "assets/images/crm1/car-with-hood-up.svg";
import towingVehicle from "assets/images/crm1/towing-vehicle.svg";
import carbehind from "assets/images/crm1/car-behind-fence.png";
import motorcycle from "assets/images/crm1/motorcycle.svg";
import largeRv from "assets/images/crm1/large-rv.svg";
import lockbox from "assets/images/crm1/lockbox-with-car.svg";
import { useTranslation } from "react-i18next";

const Services = () => {
  const { t } = useTranslation();

  const data = [
    {
      img: hoodup,
      name: t("towing.services.serv1"),
    },
    {
      img: towingVehicle,
      name: t("towing.services.serv2"),
    },
    {
      img: carbehind,
      name: t("towing.services.serv3"),
    },
    {
      img: motorcycle,
      name: t("towing.services.serv4"),
    },
    {
      img: largeRv,
      name: t("towing.services.serv5"),
    },
    {
      img: lockbox,
      name: t("towing.services.serv6"),
    },
  ];

  return (
    <Box mt="100px">
      <Container>
        <Text
          fontSize={["30px", "50px", "50px", "50px"]}
          fontWeight="bold"
          mb="80px"
          textAlign={["center", "", "initial", ""]}>
          {t("towing.services.text")}
        </Text>
        <Grid
          gridTemplateColumns={[
            "repeat(1,1fr)",
            "repeat(2,1fr)",
            "repeat(3,1fr)",
            "repeat(3,1fr)",
          ]}
          justifyContent="center"
          gap="40px"
          rowGap="80px"
          mb="80px">
          {data.map((e, i) => {
            return <ServiceCard key={i} img={e.img} text={e.name} />;
          })}
        </Grid>

        <Button
          bg="#8f09f9"
          color="white"
          fontWeight="bold"
          px="80px"
          fontSize={["18px", "25px", "25px", "25px"]}
          maxW={["100%", "initial", "initial", "initial"]}
          py="40px"
          display="flex"
          mx={["auto", "", "0", ""]}
          _hover={{}}
          _focus={{}}
          _active={{}}>
          {t("towing.services.button")}
        </Button>
      </Container>
    </Box>
  );
};

export default Services;
