import { Box, Flex, Text } from "@chakra-ui/react";

const AboutSection = ({ icon, title, text, ...props }) => {
  return (
    <Box {...props}>
      <Flex alignItems="center">
        <Box as={icon} color="#8f09f9" me="5px" />
        <Text
          fontWeight="bold"
          fontSize={["20px", "30px", "25px", "30px"]}
          color="#333333"
          mb="15px"
        >
          {title}
        </Text>
      </Flex>
      <Text
        lineHeight="25px"
        color="#333333"
        fontSize={["14px", "18px", "18px", "18px"]}
      >
        {text}
      </Text>
    </Box>
  );
};

export default AboutSection;
