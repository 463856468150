import { Box, Text, Grid } from "@chakra-ui/react";

const Card = ({ order, icon, title, text, ...props }) => {
  return (
    <Box
      bg="white"
      p={["20px", "50px", "50px", "50px"]}
      boxShadow="0px 0px 70px rgb(0 0 0 / 5%)"
      {...props}>
      <Grid
        w="80px"
        h="80px"
        borderRadius="50%"
        fontSize="35px"
        bg="#0061FE"
        placeItems="center"
        color="white"
        mb="32px">
        {order}
      </Grid>

      <Text
        fontSize="22px"
        fontWeight="bold"
        lineHeight="32px"
        mb="18px"
        color="#0A1426">
        {title}
      </Text>
      <Text color="#6C727D" lineHeight="30px">
        {text}
      </Text>
    </Box>
  );
};

export default Card;
