import { Box, Flex, Image, Text, Grid, Button } from "@chakra-ui/react";
import { Container } from "components/shared";
import serviceProvider from "assets/images/service-provider.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ServiceProviders = () => {
  const dir = localStorage.getItem("i18nextLng");

  const { t } = useTranslation();

  return (
    <Box
      pos="relative"
      minH="960px"
      overflow="hidden"
    //mb={["80px", "50px", "80px", ""]}
    >
      <Container maxW="1300px" px="15px">
        <Flex
          flexDirection={["column-reverse", "column-reverse", "row", ""]}
          mb={["30px", "30px", "0", ""]}>
          <Box
            pt={["50px", " ", "180px", "180px"]}
            w="100%"
            px={["0", "5%", "0", ""]}>
            <Text
              fontSize={["24px", "40px", "50px", "40px"]}
              lineHeight={["40px", "55px", "", ""]}
              fontWeight="bold"
              w={["98%", "80%", "80%", "80%"]}
            // textAlign={["center", "initial", "", ""]}
            >
              {t("serviceProviders.title")}
            </Text>

            <Grid
              gridTemplateColumns={["repeat(1,1fr)", "repeat(2,1fr)", "", ""]}
              w={["100%", "", "95%", "85%"]}
              gap={["30px", "40px", "0", ""]}
              mt={["40px", "80px", "", ""]}>
              <Box w={["100%", "90%", "70%", "66%"]} m={["auto", "0", "", ""]}>
                <Text
                  fontSize={["20px", "", "34px", ""]}
                  lineHeight="42px"
                  fontWeight="bold"
                  mb="15px">
                  {t("serviceProviders.one.title")}
                </Text>
                <Text fontSize="18px" lineHeight="25px" mb="15px">
                  {t("serviceProviders.one.text")}
                </Text>
                <Button
                  // as={Link}
                  // to="/towing"
                  p="20px"
                  w="100%"
                  py="30px"
                  textTransform="uppercase"
                  bg="bg.mainColor"
                  color="white"
                  fontSize="1.25rem"
                  letterSpacing="0.5px"
                  _hover={{}}
                  _active={{}}
                  _focus={{}}>
                  {t("serviceProviders.one.button")}
                </Button>
              </Box>

              <Box w={["100%", "90%", "65%", "60%"]} m={["auto", "0", "", ""]}>
                <Text
                  fontSize={["20px", "", "34px", ""]}
                  lineHeight="42px"
                  fontWeight="bold"
                  mb="15px">
                  {t("serviceProviders.two.title")}
                </Text>
                <Text fontSize="18px" lineHeight="25px" mb="15px">
                  {t("serviceProviders.two.text")}
                </Text>
                <Button
                  as={Link}
                  to="/workshop-management"
                  p="20px"
                  w="100%"
                  py="30px"
                  textTransform="uppercase"
                  color="text.mainColor"
                  bg="transparent"
                  fontSize="1.25rem"
                  letterSpacing="0.5px"
                  border="1px solid "
                  borderColor="bg.mainColor"
                  _hover={{ bg: "bg.mainColor", color: "white" }}>
                  {t("serviceProviders.two.button")}
                </Button>
              </Box>
            </Grid>
          </Box>

          <Box
            pos="relative"
            w={["100%", "", "50%", ""]}
            h={["400px", "400px", "initial", ""]}
            overflow={["hidden", "", "initial", ""]}>
            <Image
              src={serviceProvider}
              bottom="0"
              pos={["relative", "", "absolute", ""]}
              right={dir === "en" ? ["0", "", "-950px", "-800px"] : null}
              left={dir === "ar" ? ["0", "", "-950px", "-800px"] : null}
              transform={dir === "en" ? "rotateY(180deg)" : null}
              top="15px"
              h={["962px", "", "962px", ""]}
              w="1324px"
              maxW="initial"
            />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default ServiceProviders;
