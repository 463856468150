import { useContext, memo } from "react";
import { Box, Image, Flex, Heading, Text, Link } from "@chakra-ui/react";
import { Container } from "components/shared";
import wave from "assets/images/wave3.svg";
import googlePlay from "assets/images/andro.png";
import appleStore from "assets/images/apple.png";
import introImage from "assets/images/intro.png";
import { language } from "contexts/language";
import { useTranslation } from "react-i18next";

const Intro = memo(() => {
  const { t } = useTranslation();
  const { lang } = useContext(language);

  return (
    <Box
      pos="relative"
      id="home"
      bg="linear-gradient(40deg, #29B3FB 0%, #B5DDF2 100%)"
      pt="120px"
      pb="100px"
      overflow="hidden"
      minH="100vh">
      <Container>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          pos="relative"
          zIndex="11"
          flexWrap={["wrap", "", "nowrap", "nowrap"]}
          flexDirection={["column-reverse", "", "row", ""]}>
          <Box w={["100%", "80%", "60%", ""]}>
            <Heading
              as="h2"
              color="white"
              fontWeight="bold"
              fontSize={["30px", "42px", "50px", ""]}
              mb="20px"
              textAlign={["center", "", "initial", ""]}>
              {t("main.home.heading")}
            </Heading>

            <Text
              color="white"
              w={["90%", "80%", "85%", ""]}
              lineH="28px"
              textAlign={["center", "", "initial", ""]}
              m={["auto", "", "0", ""]}>
              {t("main.home.text")}
            </Text>

            <Flex
              mt="40px"
              justifyContent={["center", "", "initial", ""]}
              flexWrap={["wrap", "nowrap", "", ""]}>
              <Link
                href="https://play.google.com/store/apps/details?id=com.intcore.aerbag"
                target="_blank"
                me={["5px", "10px", "", ""]}
                mb={["10px", "0", "", ""]}>
                <Image src={googlePlay} borderRadius="5px" />
              </Link>

              <Link
                href="https://apps.apple.com/us/app/aerbag/id1532251378"
                target="_blank">
                <Image src={appleStore} borderRadius="5px" />
              </Link>
            </Flex>
          </Box>
          <Box w={["100%", "", "40%", ""]} mb={["20px", "", "0", ""]}>
            <Image
              src={introImage}
              w="280px"
              ms="auto"
              me={["auto", "auto", "0", ""]}
            />
            {/* <CarouselPhone /> */}
          </Box>
        </Flex>
      </Container>
      <Image
        src={wave}
        pos="absolute"
        bottom="0px"
        transform={lang == "ar" ? "rotatey(180deg)" : "rotatey(0deg)"}
        zIndex="1"
        display={["none", "", "block", ""]}
      />
    </Box>
  );
});

export default Intro;
