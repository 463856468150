import { Box, Text, Button, Image } from "@chakra-ui/react";
import introbg from "assets/images/crm/hero-bg-1.png";
import labtop from "assets/images/crm/hero-img-1.png";
import introImg from "assets/images/crm/hero-img2.png";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const Intro = () => {
  const { t } = useTranslation();

  return (
    <Box bg={`url(${introbg})`} pt="180px" pb="90px" backgroundSize="cover">
      <Text
        textAlign="center"
        m="auto"
        fontWeight="bold"
        lineHeight={["50px", "70px", "70px", "70px"]}
        color="white"
        fontSize={["33px", "60px", "60px", "60px"]}
        px={["15px", "0", "", ""]}
        mb="15px">
        {t("crm.intro.text1")} <br />
        {t("crm.intro.text2")}
      </Text>
      {/* <Button
        textTransform="uppercase"
        m="auto"
        display="flex"
        borderRadius="35px"
        p="25px"
        paddingInline="40px"
        bg="white"
        _hover={{ bg: "whitesmoke" }}
        _active={{}}
        _focus={{}}>
        {t("crm.intro.button")}
      </Button> */}
      <motion.div
        animate={{
          y: [23, -23, 23, -23],
        }}
        transition={{
          duration: 3,
          // ease: "easeInOut",
          repeatType: "reverse",
          repeat: Infinity,
          type: "tween",
        }}>
        <Image src={introImg} m="auto" mt="50px" w="70%" />
      </motion.div>
    </Box>
  );
};

export default Intro;
