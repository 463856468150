import {
  Box,
  Heading,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

const FaqItem = ({ title, children, ...props }) => {
  return (
    <AccordionItem border="none" {...props}>
      <Heading>
        <AccordionButton
          bg="#f2f2f2"
          py="15px"
          fontWeight="semibold"
          fontSize={["14px", "", "1rem", ""]}
        >
          <Box as="span" flex="1" textAlign="left">
            {title}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </Heading>
      <AccordionPanel pb={4}>{children}</AccordionPanel>
    </AccordionItem>
  );
};

export default FaqItem;
