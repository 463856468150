import { Box, Heading, Text, Grid, Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Container } from "components/shared";
import { IoHomeOutline } from "react-icons/io5";
import { AiOutlineUser } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const Help = () => {
  const { t } = useTranslation();

  return (
    <Box pt="180px" mb="80px">
      <Container>
        <Text
          textAlign="center"
          color="rgb(65, 64, 66,0.7)"
          mb="40px"
          fontSize={["18px", "20px", "", ""]}>
          {t("help.text1")}
        </Text>
        <Heading
          as="h2"
          color="#414042"
          fontWeight="bolder"
          textAlign="center"
          fontSize={["40px", "60px", "80px", ""]}
          mb="60px">
          {t("help.text2")}
        </Heading>

        <Grid
          gridTemplateColumns={["repeat(1,1fr)", "", "repeat(2,1fr)", ""]}
          gap="25px"
          px={["0", "50px", "0", ""]}>
          <Box as={Link} to="service-providers">
            <Flex
              bg="#29b3fb"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              py="40px"
              borderRadius="10px"
              transition="0.3s"
              _hover={{ bg: "#1b85bd" }}>
              <Grid
                border="1px solid white"
                placeItems="center"
                p="10px"
                borderRadius="5px"
                mb="15px">
                <Box as={AiOutlineUser} fontSize="40px" color="white" />
              </Grid>

              <Text color="white" fontSize="18px">
                {t("help.title1")}
              </Text>
            </Flex>
          </Box>

          <Box as={Link} to="car-owner">
            <Flex
              bg="#29b3fb"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              py="40px"
              borderRadius="10px"
              transition="0.3s"
              _hover={{ bg: "#1b85bd" }}>
              <Grid
                border="1px solid white"
                placeItems="center"
                p="10px"
                borderRadius="5px"
                mb="15px">
                <Box as={IoHomeOutline} fontSize="40px" color="white" />
              </Grid>

              <Text color="white" fontSize="18px">
                {t("help.title2")}
              </Text>
            </Flex>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

export default Help;
