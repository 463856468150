import { Box, Flex, Grid, Text, Button } from "@chakra-ui/react";
import ScrollAnimation from "react-animate-on-scroll";

import { useTranslation } from "react-i18next";

const PlanCard = ({ price, title, items, delay, icon, ...props }) => {
  const { t } = useTranslation();

  const List = ({ text, order, ...props }) => {
    return (
      <Flex alignItems="center" mb="15px">
        <Grid
          me="8px"
          placeItems="center"
          bg="rgba(7, 213, 172, 0.2)"
          color="#07D5AC"
          w="20px"
          h="20px"
          borderRadius="50%"
          fontSize="12px"
          {...props}>
          {order}
        </Grid>
        <Text m="0" color="#6C727D">
          {text}
        </Text>
      </Flex>
    );
  };

  return (
    <Box {...props}>
      <ScrollAnimation animateIn="slideInUp " animateOnce={true} delay={delay}>
        <Box
          p="50px 40px"
          boxShadow="0px 0px 70px rgb(0 0 0 / 5%)"
          borderRadius="5px"
          w="100%">
          <Flex flexWrap={["wrap", "wrap", "nowrap", ""]}>
            <Grid
              placeItems="center"
              w="80px"
              h="80px"
              borderRadius="50%"
              bg="rgba(158, 89, 255, 0.1)"
              color="#5956E9"
              me={["auto", "", "25px", ""]}
              ms={["auto", "", "0", ""]}>
              <Box as={icon} fontSize="40px" />
            </Grid>
            <Box w={["100%", "", "initial", ""]} mx={["auto", "", "0", ""]}>
              <Text
                lineHeight="30px"
                color="#0A1426"
                textAlign={["center", "", "initial", ""]}>
                {title}
              </Text>
              <Text
                fontWeight="bold"
                fontSize={["22px", "36px", "36px", "36px"]}
                lineHeight="50px"
                color="#0A1426"
                textAlign={["center", "", "initial", ""]}>
                {price}{" "}
                <Text as="sup" fontSize="14px" verticalAlign="super">
                  {t("crm.pricing.currency")}
                </Text>
                <Text as="span" fontSize="16px">
                  / {t("crm.pricing.month")}
                </Text>
              </Text>
            </Box>
          </Flex>
          <Box my="30px">
            <hr />
          </Box>
          {items?.map((e, i) => {
            return <List key={i} text={e} order={i + 1} />;
          })}

          <Button
            as={"a"}
            href="https://portal.aerbag.app"
            target="_blank"
            textTransform="uppercase"
            display="flex"
            borderRadius="35px"
            mt="30px"
            w="100%"
            p="25px"
            paddingInline="40px"
            color="#0061FE"
            border="1px solid #0061FE"
            bg="transparent"
            _hover={{ bg: "#0061FE", color: "white" }}
            _active={{}}
            _focus={{}}>
            {t("crm.pricing.button")}
          </Button>
        </Box>
      </ScrollAnimation>
    </Box>
  );
};

export default PlanCard;
