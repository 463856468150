import { Box, Text, Button, Grid } from "@chakra-ui/react";
import bgIntro from "assets/images/crm1/towing-hero.jpg";
import { useTranslation } from "react-i18next";

const Intro = () => {
  const { t } = useTranslation();

  return (
    <Grid
      bg={`url(${bgIntro})`}
      minH="72vh"
      bgSize="cover"
      bgPos="center 59%"
      pt="150px"
      px={["15px", "15px", "0", ""]}
      //placeItems="center"
    >
      <Box>
        <Text
          textAlign="center"
          color="#26242D"
          fontSize={["25px", "40px", "50px", "50px"]}
          fontWeight="bold"
          mb="15px">
          {t("towing.intro.text1")}
        </Text>
        <Text
          textAlign="center"
          color="#26242D"
          fontSize={["18px", "20px", "25px", "25px"]}
          mb="40px">
          {t("towing.intro.text2")}
        </Text>
        <Button
          display="flex"
          m="auto"
          bg="#FFCA40"
          fontWeight="bold"
          p="30px"
          fontSize={["20px", "25px", "25px", "25px"]}
          py="40px"
          _hover={{}}
          _focus={{}}
          _active={{}}>
          {t("towing.intro.button")}
        </Button>
      </Box>
    </Grid>
  );
};

export default Intro;
