import { Box, Text } from "@chakra-ui/react";
import { Container } from "components/shared";
import { useTranslation } from "react-i18next";

const Counter = () => {
  const { t } = useTranslation();

  return (
    <Box bg="#0061FE" py="100px" mb="100px">
      <Container>
        <Text
          textAlign="center"
          fontWeight="bold"
          fontSize={["30px", "45px", "45px", "45px"]}
          w="70%"
          m="auto"
          color="white">
          {t("crm.counter.text")}
        </Text>
      </Container>
    </Box>
  );
};

export default Counter;
