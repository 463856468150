import { Box, Grid, Flex } from "@chakra-ui/react";
import { Container } from "components/shared";
import { TbPremiumRights } from "react-icons/tb";
import { MdOutlineFreeBreakfast } from "react-icons/md";
import PlanCard from "./plan-card";
import TitleText from "../title-text";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";

const Prices = () => {
  const { t } = useTranslation();
  const data = [
    {
      title: t("crm.pricing.card1.title"),
      items: [
        t("crm.pricing.card1.text1"),
        t("crm.pricing.card1.text2"),
        t("crm.pricing.card1.text3"),
        t("crm.pricing.card1.text4"),
      ],
      delay: 0,
      price: 0,
      icon: MdOutlineFreeBreakfast,
    },

    {
      title: t("crm.pricing.card2.title"),
      items: [
        t("crm.pricing.card2.text1"),
        t("crm.pricing.card2.text2"),
        t("crm.pricing.card2.text3"),
        t("crm.pricing.card2.text4"),
      ],
      delay: 100,
      price: 350,
      icon: TbPremiumRights,
    },

    // {
    //   title: t("crm.pricing.card3.title"),
    //   items: [
    //     t("crm.pricing.card3.text1"),
    //     t("crm.pricing.card3.text1"),
    //     t("crm.pricing.card3.text1"),
    //     t("crm.pricing.card3.text1"),
    //   ],
    //   delay: 200,
    // },
  ];

  return (
    <Box mb="100px">
      <ScrollAnimation animateIn="slideInUp " animateOnce={true}>
        <TitleText
          title={t("crm.pricing.text1")}
          des={t("crm.pricing.text2")}
          text2={t("crm.pricing.text3")}
        />
      </ScrollAnimation>

      <Container>
        {/* <Grid
          gridTemplateColumns={[
            "repeat(1,1fr)",
            "repeat(1,1fr)",
            "repeat(3,1fr)",
            "",
          ]}
          gap="30px"
          px={["5%", "20%", "0", ""]}>

       
     
        </Grid> */}

        <Flex
          justifyContent="center"
          flexWrap={["wrap", "nowrap", "nowrap", "nowrap"]}>
          {data?.map((e, i) => {
            return (
              <PlanCard
                w={["100%", "48%", "40%", "40%"]}
                me={i == 0 ? ["0", "20px", "50px", "50px"] : "0"}
                key={i}
                title={e.title}
                items={e.items}
                delay={e.delay}
                price={e.price}
                icon={e.icon}
              />
            );
          })}
        </Flex>
      </Container>
    </Box>
  );
};

export default Prices;
