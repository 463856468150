import { Box, Flex, Text, Button, Image } from "@chakra-ui/react";
import shape1 from "assets/images/crm/shape-1.png";
import img1 from "assets/images/crm/img1.png";
import img2 from "assets/images/crm/img1s.png";
import { motion } from "framer-motion";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from "react-i18next";

const Row = () => {
  const { t } = useTranslation();

  const dir = localStorage.getItem("i18nextLng");

  return (
    <Box mb={["200px", "100px", "100px", "100px"]}>
      <Flex flexWrap={["wrap", "", "nowrap", "nowrap"]}>
        <Box
          w={["100%", "", "40%", "40%"]}
          me="50px"
          mt="50px"
          mb={["20px", "", "0", "0"]}>
          <ScrollAnimation animateIn="slideInUp " animateOnce={true}>
            <Box
              maxW="170px"
              bg="rgba(158, 89, 255, 0.1)"
              color="#9E59FF"
              borderRadius="35px"
              py="8px"
              textAlign="center"
              fontSize="14px"
              fontWeight="500"
              textTransform="capitalize"
              mb="20px">
              {t("crm.about.service1.text1")}
            </Box>
            <Text
              fontSize={["25px", "36px", "36px", "36px"]}
              fontWeight="bold"
              lineHeight={["30px", "50px", "50px", "50px"]}
              mb="18px"
              color="#0A1426">
              {t("crm.about.service1.text2")}
            </Text>
            <Text color="#6C727D" lineHeight="30px" mb="35px">
              {t("crm.about.service1.text3")}
            </Text>
            {/* <Button
              textTransform="uppercase"
              display="flex"
              borderRadius="35px"
              p="25px"
              paddingInline="40px"
              color="#0061FE"
              border="1px solid #0061FE"
              bg="transparent"
              _hover={{ bg: "#0061FE", color: "white" }}
              _active={{}}
              _focus={{}}>
              {t("crm.about.service1.button")}
            </Button> */}
          </ScrollAnimation>
        </Box>

        <Box pos="relative" w={["100%", "", "initial", ""]}>
          <Image src={shape1} mx={["auto", "", "0", ""]} w="80%" />
          <motion.div
            animate={{
              y: [-200, -180],
            }}
            transition={{
              duration: 1.5,
              ease: "easeInOut",
              repeatType: "reverse",
              repeat: Infinity,
              type: "tween",
            }}>
            <Image
              borderRadius="5px"
              src={img1}
              pos="absolute"
              top="50%"
              transform="translateY(-50%)"
              right={dir == "en" ? ["0", "0", "-20px", "-120px"] : null}
              left={dir == "ar" ? ["0", "0", "-20px", "-120px"] : null}
              filter="drop-shadow(0px 10px 50px rgba(0, 0, 0, 0.1))"
            />
          </motion.div>
          <motion.div
            initial={{ y: -200 }}
            animate={{
              x: [15, -15],
            }}
            transition={{
              duration: 1.5,
              ease: "easeInOut",
              repeatType: "reverse",
              repeat: Infinity,
              type: "tween",
            }}>
            <Image
              src={img2}
              pos="absolute"
              top="50%"
              w="150px"
              // h="327px"
              right={dir == "ar" ? "30px" : null}
              left={dir == "en" ? "30px" : null}
              filter="drop-shadow(0px 10px 50px rgba(0, 0, 0, 0.1))"
            />
          </motion.div>
        </Box>
      </Flex>
    </Box>
  );
};

export default Row;
