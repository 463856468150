
import { Box } from "@chakra-ui/react";
import { Intro, About, Services, Faq } from "components/home";

const Home = () => {

  return (
    <Box>
      <Intro />
      <About />
      <Services />
      <Faq />
    </Box>
  );
};

export default Home;
