import { Box, Heading, Text, Link as L } from "@chakra-ui/react";
import { TitleText, Container, HeaderPage } from "components/shared";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

const Terms = () => {
  const { t } = useTranslation();

  const CustomText = ({ children }) => {
    return (
      <Text opacity="0.6" lineHeight="2.1" color="#333" fontSize="14px">
        {children}
      </Text>
    );
  };

  return (
    <Box pt="130px">
      <Container>
        <Box mb="50px">
          <HeaderPage
            name={t("terms.header")}
            color="#414042"
            letterSpacing="2px"
          />

          <Heading
            as="h2"
            color="#333"
            fontSize={["18px", "", "22px", ""]}
            fontWeight="bold"
            mb="20px"
          >
            {t("terms.acceptance.title")}
          </Heading>
          <Text opacity="0.6" lineHeight="2.1" color="#333" fontSize="14px">
            <Trans i18nKey="terms.acceptance.text1" />
            <br />
            <br />
            <Trans i18nKey="terms.acceptance.text2" />
          </Text>

          <Text
            textDecor="underline"
            fontSize="14px"
            mt="20px"
            color="#333"
            lineHeight="28px"
          >
            <Trans i18nKey="terms.acceptance.text3" />
          </Text>
        </Box>

        <TitleText title={t("terms.updates.title")}>
          <CustomText>
            <Trans i18nKey="terms.updates.text1" /> <br /> <br />
            <Trans i18nKey="terms.updates.text2" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.eligibility.title")}>
          <CustomText>
            <Trans i18nKey="terms.eligibility.text1" /> <br />
            <br />
            <Trans i18nKey="terms.eligibility.text2" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.registration.title")}>
          <CustomText>
            <Trans i18nKey="terms.registration.text1" />
            <br />
            <br />
            <Trans i18nKey="terms.registration.text2" />
            <br />
            <br />
            <Trans i18nKey="terms.registration.text3" /> <br />
            <br />
            <Trans i18nKey="terms.registration.text4" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.vehicle_services.title")}>
          <CustomText>
            <Trans i18nKey="terms.vehicle_services.text1" />
            <br />
            <br />
            <Trans i18nKey="terms.vehicle_services.text2" />
            <br />
            <br />
            <Trans i18nKey="terms.vehicle_services.text3" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.fees_and_payments.title")}>
          <CustomText>
            <Trans i18nKey="terms.fees_and_payments.text1" />
            <br />
            <br />
            <Trans i18nKey="terms.fees_and_payments.text2" />
            <br />
            <br />
            <Trans i18nKey="terms.fees_and_payments.text3" />
            <br />
            <br />
            <Trans i18nKey="terms.fees_and_payments.text4" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.subscription_services.title")}>
          <CustomText>
            <Trans i18nKey="terms.subscription_services.text1" />
            <br />
            <br />
            <Trans i18nKey="terms.subscription_services.text2" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.fees_Payments_Subscription.title")}>
          <CustomText>
            <Trans i18nKey="terms.fees_Payments_Subscription.text1" />
            <br />
            <br />
            <Trans i18nKey="terms.fees_Payments_Subscription.text2" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.payment_credit.title")}>
          <CustomText>
            <Trans i18nKey="terms.payment_credit.text1" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.publicity.title")}>
          <CustomText>
            <Trans i18nKey="terms.publicity.text1" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.customer_data.title")}>
          <CustomText>
            <Trans i18nKey="terms.customer_data.text1" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.privacy.title")}>
          <CustomText>
            <Trans i18nKey="terms.privacy.text1" />{" "}
            <Box as={Link} to="/privacy" color="text.mainColor">
              <Trans i18nKey="terms.privacy.link" />{" "}
            </Box>
            <br />
            <br />
            <Trans i18nKey="terms.privacy.text2" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.user_data_analytics.title")}>
          <CustomText>
            <Trans i18nKey="terms.user_data_analytics.text1" />
            <br />
            <br />
            <Trans i18nKey="terms.user_data_analytics.text2" />
            <br />
            <br />
            <Trans i18nKey="terms.user_data_analytics.text3" />
            <br />
            <br />
            <Trans i18nKey="terms.user_data_analytics.text4" />
            <br />
            <br />
            <Trans i18nKey="terms.user_data_analytics.text5" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.user_generated_content.title")}>
          <CustomText>
            <Trans i18nKey="terms.user_generated_content.text1" />
            <br />
            <br />
            <Trans i18nKey="terms.user_generated_content.text2" />
            <br />
            <br />
            <Trans i18nKey="terms.user_generated_content.text3" />
            <br />
            <br />
            <Trans i18nKey="terms.user_generated_content.text4" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.user_rules.title")}>
          <CustomText>
            <Trans i18nKey="terms.user_rules.text1" />
            <br />
            <br />
            <Trans i18nKey="terms.user_rules.text2" />
            <br />
            <br />
            <Trans i18nKey="terms.user_rules.text3" />
            <br />
            <br />
            <Trans i18nKey="terms.user_rules.text4" />
            <br />
            <br />
            <Trans i18nKey="terms.user_rules.text5" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.connections.title")}>
          <CustomText>
            <Trans i18nKey="terms.connections.text1" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.advertising_and_promotions.title")}>
          <CustomText>
            <Trans i18nKey="terms.advertising_and_promotions.text1" />
            <br />
            <br />
            <Trans i18nKey="terms.advertising_and_promotions.text2" />
            <br />
            <br />
            <Trans i18nKey="terms.advertising_and_promotions.text3" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.proprietary_rights.title")}>
          <CustomText>
            <Trans i18nKey="terms.proprietary_rights.text1" />
            <br />
            <br />
            <Trans i18nKey="terms.proprietary_rights.text2" />
            <br />
            <br />
            <Trans i18nKey="terms.proprietary_rights.text3" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.license.title")}>
          <CustomText>
            <Trans i18nKey="terms.license.text1" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.user_content_license.title")}>
          <CustomText>
            <Trans i18nKey="terms.user_content_license.text1" />
            <br />
            <br />
            <Trans i18nKey="terms.user_content_license.text2" />
            <br />
            <br />
            <Trans i18nKey="terms.user_content_license.text3" />
            <br />
            <br />
            <Trans i18nKey="terms.user_content_license.text4" />
            <br />
            <br />
            <Trans i18nKey="terms.user_content_license.text5" />
            <br />
            <br />
            <Trans i18nKey="terms.user_content_license.text6" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.use_information.title")}>
          <CustomText>
            <Trans i18nKey="terms.use_information.text1" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.cellular_wireless_service.title")}>
          <CustomText>
            <Trans i18nKey="terms.cellular_wireless_service.text1" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.location_based_services.title")}>
          <CustomText>
            <Trans i18nKey="terms.location_based_services.text1" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.feedback_license.title")}>
          <CustomText>
            <Trans i18nKey="terms.feedback_license.text1" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.termination.title")}>
          <CustomText>
            <Trans i18nKey="terms.termination.text1" />
            <L href="mailto:legal@aerbag.com" color="text.mainColor">
              <Trans i18nKey="terms.termination.link" />{" "}
            </L>

            <Trans i18nKey="terms.termination.text2" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.no_warranties.title")}>
          <CustomText>
            <Trans i18nKey="terms.no_warranties.text1" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.limitation_liability.title")}>
          <CustomText>
            <Trans i18nKey="terms.limitation_liability.text1" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.indemnification.title")}>
          <CustomText>
            <Trans i18nKey="terms.indemnification.text1" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.integration.title")}>
          <CustomText>
            <Trans i18nKey="terms.integration.text1" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.general_provisions.title")}>
          <CustomText>
            <Trans i18nKey="terms.general_provisions.text1" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.copyright_and_trademark.title")}>
          <CustomText>
            <Trans i18nKey="terms.copyright_and_trademark.text1" />
          </CustomText>

          <CustomText>
            <Trans i18nKey="terms.copyright_and_trademark.text2" />
          </CustomText>
        </TitleText>

        <TitleText title={t("terms.terms_required_by_apple.title")}>
          <CustomText>
            <Trans i18nKey="terms.terms_required_by_apple.text1" />
          </CustomText>

          <CustomText>
            <Trans i18nKey="terms.terms_required_by_apple.text2" />
          </CustomText>

          <CustomText>
            <Trans i18nKey="terms.terms_required_by_apple.text3" />
          </CustomText>

          <CustomText>
            <Trans i18nKey="terms.terms_required_by_apple.text4" />
          </CustomText>

          <CustomText>
            <Trans i18nKey="terms.terms_required_by_apple.text5" />
          </CustomText>

          <CustomText>
            <Trans i18nKey="terms.terms_required_by_apple.text6" />
          </CustomText>

          <CustomText>
            <Trans i18nKey="terms.terms_required_by_apple.text7" />
          </CustomText>

          <CustomText>
            <Trans i18nKey="terms.terms_required_by_apple.text8" />
            <L href="mailto:legal@aerbag.com" color="text.mainColor">
              <Trans i18nKey="terms.terms_required_by_apple.link" />{" "}
            </L>
          </CustomText>
        </TitleText>
      </Container>
    </Box>
  );
};

export default Terms;
