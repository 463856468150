import { Box } from "@chakra-ui/react";
import Header from "./header";
import Footer from "./footer";

const Layout = ({ children, active }) => {
  return (
    <Box>
      <Header active={active} />
      <Box minH="450px">{children}</Box>
      <Footer />
    </Box>
  );
};

export default Layout;
