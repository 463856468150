import { Box, Text } from "@chakra-ui/react";

const TitleText = ({ title, des, text2 }) => {
  return (
    <>
      <Box
        maxW="135px"
        bg="rgba(158, 89, 255, 0.1)"
        color="#9E59FF"
        borderRadius="35px"
        m="auto"
        py="10px"
        textAlign="center"
        fontSize="14px"
        fontWeight="500"
        textTransform="capitalize"
        mb="20px"
      >
        {title}
      </Box>
      <Text
        fontSize={["22px", "36px", "36px", "36px"]}
        lineHeight={["40px", "50px", "50px", "50px"]}
        textTransform="capitalize"
        color="#0A1426"
        fontWeight="bold"
        textAlign="center"
        mb="50px"
        px={["5px", "0", "", ""]}
      >
        {des}
        <br /> {text2}
      </Text>
    </>
  );
};

export default TitleText;
