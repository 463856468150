import { useContext } from "react";
import { Box, Flex, Heading, Image, Text, Grid } from "@chakra-ui/react";
import { Container } from "components/shared";
import { HiWrench, HiOutlineHandThumbUp } from "react-icons/hi2";
import { IoMdPricetags } from "react-icons/io";
import { AiFillCompass, AiFillStar } from "react-icons/ai";
import { FiCheckCircle } from "react-icons/fi";
import { FaHeadphonesAlt, FaLocationArrow } from "react-icons/fa";
import circle from "assets/images/circle.png";
import services from "assets/images/servs.png";
import { language } from "contexts/language";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  const { lang } = useContext(language);

  const List = ({ icon, name }) => {
    return (
      <Flex alignItems="center" mb="12px">
        <Box as={icon} me="5px" color="text.mainColor" />
        <Text fontWeight="semibold" _groupHover={{ color: "white" }}>
          {name}
        </Text>
      </Flex>
    );
  };

  const TextIcon = ({ icon, name }) => {
    return (
      <Box
        bg="#f8f8ff"
        borderRadius="10px"
        p="15px"
        transition=".3s"
        _hover={{ boxShadow: "0 0 10px 5px whitesmoke" }}>
        <Grid
          mx="auto"
          bg="rgba(94, 44, 237, 0.05)"
          w="70px"
          h="70px"
          placeItems="center"
          borderRadius="50%"
          mb="15px">
          <Box as={icon} fontSize="25px" color="text.mainColor" />
        </Grid>
        <Text fontWeight="bold" textAlign="center" cursor="default">
          {name}
        </Text>
      </Box>
    );
  };

  return (
    <Box id="about" pt="100px" pos="relative" overflow="hidden" mb="50px">
      <Container>
        <Flex
          alignItems="center"
          justifyContent={["center", "", "space-between", ""]}>
          <Box w={["90%", "80%", "60%", ""]}>
            <Heading
              as="h3"
              color="text.dark"
              fontSize={["25px", "35px", "", ""]}
              mb="30px"
              fontWeight="bold"
              textAlign={["center", "", "initial", ""]}>
              {t("main.about.heading")}
            </Heading>
            <Text
              mb="15px"
              lineHeight="26px"
              color="text.gray"
              textAlign={["center", "", "initial", ""]}
              fontSize={["14px", "16px", "", ""]}>
              {t("main.about.text1")}
            </Text>

            <Text
              lineHeight="26px"
              color="text.gray"
              textAlign={["center", "", "initial", ""]}
              fontSize={["14px", "16px", "", ""]}>
              {t("main.about.text2")}
            </Text>

            <Box mt="20px" display={["none", "", "block", ""]}>
              <List icon={HiWrench} name={t("main.about.text3")} />
              <List icon={AiFillStar} name={t("main.about.text4")} />
              <List icon={AiFillCompass} name={t("main.about.text5")} />
              <List icon={IoMdPricetags} name={t("main.about.text6")} />
            </Box>
          </Box>

          <Box pos="relative" display={["none", "", "block", ""]}>
            <Image
              src={services}
              pos="relative"
              zIndex="111"
              w="550px"
              ms="60px"
            />
            <Image
              src={circle}
              pos="absolute"
              top="50%"
              left="50%"
              transform={
                lang == "ar" ? "translate(-70%,-70%)" : "translate(-30%,-70%)"
              }
              zIndex="11"
            />
          </Box>
        </Flex>

        <Grid
          gridTemplateColumns={[
            "repeat(1,1fr)",
            "repeat(2,1fr)",
            "repeat(4,1fr)",
            "",
          ]}
          gap="20px"
          mt="50px"
          mx={["10%", "0", "", ""]}>
          <TextIcon icon={FiCheckCircle} name={t("main.about.text7")} />
          <TextIcon icon={HiOutlineHandThumbUp} name={t("main.about.text8")} />
          <TextIcon icon={FaHeadphonesAlt} name={t("main.about.text9")} />
          <TextIcon icon={FaLocationArrow} name={t("main.about.text10")} />
        </Grid>
      </Container>
    </Box>
  );
};

export default About;
