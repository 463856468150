
import { Box, Image, Heading, Text, Flex, Grid } from "@chakra-ui/react";
import wave from "assets/images/wave2.svg";
import carService from "assets/images/car_servs.png";
import towing from "assets/images/towing.png";
import lu7ah from "assets/images/lu7ah.png";
import lu7ah2 from "assets/images/lu7ah2.png";
import carWash from "assets/images/car_wash.png";
import { Container } from "components/shared";
import { useTranslation } from "react-i18next";

const Services = () => {
  const { t } = useTranslation();
  const servicesArr = [
    {
      name: t("main.services.auto_repair.heading"),
      text: t("main.services.auto_repair.text"),
      src: carService,
    },
    {
      name: t("main.services.towing.heading"),
      text: t("main.services.towing.text"),
      src: towing,
    },
    {
      name: t("main.services.lu7ah.heading"),
      text: t("main.services.lu7ah.text"),
      src: lu7ah,
    },
    {
      name: t("main.services.mobile_car.heading"),
      text: t("main.services.mobile_car.text"),
      src: carWash,
    },
  ];

  const Service = ({ src, name, text }) => {
    return (
      <Box
        bg="white"
        p="20px"
        pt="30px"
        borderRadius="10px"
        transition=".3s"
        cursor="pointer"
        boxShadow="0 0 17px 8px #f7f7f7"
        _hover={{ transform: "scale(1.1)" }}>
        <Image
          src={src}
          m="auto"
          w="150px"
          h="100px"
          mb="20px"
          objectFit="contain"
        />
        <Heading
          fontWeight="semibold"
          fontSize="22px"
          mb="20px"
          textAlign="center"
          color="#4c4c4c">
          {name}
        </Heading>

        <Text
          // color="text.gray"
          color="#4c4c4c"
          textAlign="center"
          fontSize="15px"
          lineH="26px">
          {text}
        </Text>
      </Box>
    );
  };

  return (
    <Box id="services" bg="#f8f8ff" pos="relative" pb="50px">
      <Container>
        <Box pos="relative" zIndex="11" pt="100px">
          <Heading textAlign="center" mb="20px" fontWeight="semibold">
            {t("main.services.heading")}
          </Heading>
          <Text
            textAlign="center"
            w={["90%", "", "55%", ""]}
            m="auto"
            lineHeight="26px"
            color="text.gray">
            {t("main.services.text")}
          </Text>
        </Box>
        <Flex justifyContent="space-between" pt="100px">
          <Image
            src={lu7ah2}
            pos="relative"
            zIndex="1"
            w="40%"
            display={["none", "", "block", ""]}
          />
          <Grid
            w={["100%", "", "55%", ""]}
            gridTemplateColumns={["repeat(1,1fr)", "repeat(2,1fr)", "", ""]}
            mx={["10%", "0", "", ""]}
            gap="25px"
            mt="10px">
            {servicesArr.map((e, i) => {
              return (
                <Service key={i} src={e.src} name={e.name} text={e.text} />
              );
            })}
          </Grid>
        </Flex>
      </Container>
      <Image
        src={wave}
        pos="absolute"
        top="0"
        display={["none", "", "block", ""]}
      // w="100%"
      // h="140px"
      // objectFit="cover"
      />
    </Box>
  );
};

export default Services;
