import { useContext } from "react";
import { language } from "contexts/language";
import { Box, Flex, Text } from "@chakra-ui/react";
import { FaGlobe } from "react-icons/fa";
import i18next from "i18n";
import { useTranslation } from "react-i18next";

const Lang = ({ isCenter, ...props }) => {
  const { lang, setLang } = useContext(language);
  const { t } = useTranslation();
  //   const lang = localStorage.getItem("i18nextLng");

  const handleLang = () => {
    if (lang == "ar") {
      setLang("en");
      i18next.changeLanguage("en");
    } else {
      setLang("ar");
      i18next.changeLanguage("ar");
    }
  };

  return (
    <Box onClick={handleLang} {...props}>
      <Flex
        alignItems="center"
        cursor="pointer"
        justifyContent={isCenter ? "center" : "initial"}>
        <Text me="10px" fontSize="18px" color="text.700">
          {t("lang")}
        </Text>
        <Box as={FaGlobe} color="text.100" />
      </Flex>
    </Box>
  );
};

export default Lang;
